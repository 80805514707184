import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./user/auth";
import dashboard from "./store_dashboard/dashboard";
import userDashboard from "./user/userDashboard";
import promoCode from "./store_dashboard/promoCode";

export default createStore({
  modules: {
    auth,
    dashboard,
    userDashboard,
    promoCode
  },
  plugins: [createPersistedState()],
});
