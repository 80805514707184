import http from "@/helper/http";
import api from "@/helper/api";

export default {
    state: {
        loadingPromoCodes: false,
        loadingCreatePromoCode: false,
        loadingCopyPromoCode: false,
        loadingActivatePromoCode: false,
        loadingUpdatePromoCode: false,
        promoCodes: []
    },
    mutations: {
        SET_LOADING_PROMO_CODES(state, loadingPromoCodes) {
            state.loadingPromoCodes = loadingPromoCodes;
        },
        SET_LOADING_CREATE_PROMO_CODE(state, loading) {
            state.loadingCreatePromoCode = loading; // Mutation for creating promo codes loading state
        },
        SET_LOADING_COPY_PROMO_CODE(state, loading) {
            state.loadingCopyPromoCode = loading; // Mutation for creating promo codes loading state
        },
        SET_LOADING_ACTIVATE_PROMO_CODE(state, loading) {
            state.loadingActivatePromoCode = loading; // Mutation for creating promo codes loading state
        },
        SET_LOADING_UPDATE_PROMO_CODE(state, loading) {
            state.loadingUpdatePromoCode = loading; // Mutation to handle the loading state of updating promo codes
        },
        SET_PROMO_CODES(state, promoCodes) {
            state.promoCodes = promoCodes;
        },
        ADD_PROMO_CODE(state, promoCode) {
            state.promoCodes.push(promoCode); // Add the new promo code to the list
        },
        UPDATE_PROMO_CODE(state, updatedPromoCode) {
            const index = state.promoCodes.findIndex(code => code.id === updatedPromoCode.id);
            if (index !== -1) {
                state.promoCodes.splice(index, 1, updatedPromoCode); // Replace the old promo code with the updated one
            }
        },
    },
    actions: {
        getAllPromoCodes({ commit }, store_id) {
            commit("SET_LOADING_PROMO_CODES", true);
            return new Promise((resolve, reject) => {
                http
                    .get(`${api.store_promo_codes}/${store_id}`)
                    .then((response) => {
                        commit("SET_PROMO_CODES", response?.data);
                        commit("SET_LOADING_PROMO_CODES", false);
                        resolve(response?.data);
                    })
                    .catch((error) => {
                        commit("SET_LOADING_PROMO_CODES", false);
                        reject(error);
                    });
            });
        },
        createPromoCode({ commit }, { store_id, promoCodeData }) {
            commit("SET_LOADING_CREATE_PROMO_CODE", true); // Set loading for creating promo codes
            return new Promise((resolve, reject) => {
                http
                    .post(`${api.store_promo_codes}/${store_id}`, promoCodeData)
                    .then((response) => {
                        commit("ADD_PROMO_CODE", response.data); // Commit the new promo code
                        commit("SET_LOADING_CREATE_PROMO_CODE", false); // Stop loading
                        resolve(response.data);
                    })
                    .catch((error) => {
                        commit("SET_LOADING_CREATE_PROMO_CODE", false); // Stop loading on error
                        reject(error);
                    });
            });
        },
        copyPromoCodesToStores({ commit }, payload) {
            commit("SET_LOADING_COPY_PROMO_CODE", true);
            return new Promise((resolve, reject) => {
                http
                    .post(`${api.copy_promo_codes}`, payload)
                    .then((response) => {
                        commit("SET_LOADING_COPY_PROMO_CODE", false);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        commit("SET_LOADING_COPY_PROMO_CODE", false);
                        reject(error);
                    });
            });
        },
        activatePromoCode({ commit }, payload) {
            commit("SET_LOADING_ACTIVATE_PROMO_CODE", true);
            return new Promise((resolve, reject) => {
                http
                    .post(`${api.update_promo_code}/${payload.id}/${payload.type}`)
                    .then((response) => {
                        const updatedPromoCode = response.data;
                        commit("UPDATE_PROMO_CODE", updatedPromoCode);
                        commit("SET_LOADING_ACTIVATE_PROMO_CODE", false);
                        resolve(updatedPromoCode);
                    })
                    .catch((error) => {
                        commit("SET_LOADING_ACTIVATE_PROMO_CODE", false);
                        reject(error);
                    });
            });
        },
        updatePromoCode({ commit }, payload) {
            commit("SET_LOADING_UPDATE_PROMO_CODE", true);
            return new Promise((resolve, reject) => {
                http
                    .patch(`${api.update_promo_code}/${payload.id}`, payload.data) // Use PUT or PATCH for update
                    .then((response) => {
                        const updatedPromoCode = response.data;
                        commit("UPDATE_PROMO_CODE", updatedPromoCode);
                        commit("SET_LOADING_UPDATE_PROMO_CODE", false);
                        resolve(updatedPromoCode);
                    })
                    .catch((error) => {
                        commit("SET_LOADING_UPDATE_PROMO_CODE", false);
                        reject(error);
                    });
            });
        },
    },
    getters: {
        isLoadingPromoCodes: (state) => state.loadingPromoCodes,
        isLoadingCopyPromoCode: (state) => state.loadingCopyPromoCode,
        isLoadingCreatePromoCode: (state) => state.loadingCreatePromoCode, // Getter for create promo code loading state
        getPromoCodes: (state) => state.promoCodes, // Getter to access promo codes
        isLoadingActivatePromoCode: (state) => state.loadingActivatePromoCode,
        isLoadingUpdatePromoCode: (state) => state.loadingUpdatePromoCode,
    },
}
