<template>
    <div class="relative inline-block text-left" @click="toggleDropdown">
        <button
            class="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            {{ selectedOption || 'Select an option' }}
            <svg class="w-5 h-5 ml-2 -mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 10l5 5 5-5H7z" />
            </svg>
        </button>

        <div v-if="isOpen" class="absolute right-0 z-10 mt-2 w-56 bg-white border border-gray-200 rounded-md shadow-lg">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    v-for="(option, index) in options" :key="index" @click.stop="selectOption(option)">
                    {{ option }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HSDropdown",
    props: {
        options: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isOpen: false,
            selectedOption: null
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectOption(option) {
            this.selectedOption = option;
            this.isOpen = false; // Close dropdown after selection
            this.$emit('option-selected', option); // Emit event with selected option
        }
    }
};
</script>

<style scoped>
/* No additional styles needed as Tailwind is used */
</style>