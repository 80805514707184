import * as Yup from "yup";

export const promoCodeSchema = Yup.object().shape({
    code: Yup.string().required("Promo code is required"),
    //   discount_percentage: Yup.number()
    //     .min(0, "Discount must be at least 0%")
    //     .max(100, "Discount can't exceed 100%")
    //     .required("Discount percentage is required"),
    discount_percentage: Yup.number().nullable(),
    discount_amount: Yup.number().nullable(),
    max_allowed_total_discount_amount: Yup.number().nullable(),
    expiration_date: Yup.date()
        .min(new Date(), "Expiration date can't be in the past")
        .required("Expiration date is required"),
    minimum_purchase_required: Yup.number()
        .min(0, "Minimum purchase must be at least $0")
        .required("Minimum purchase is required"),
    usage_limit: Yup.number()
        .min(1, "Usage limit must be at least 1")
        .required("Usage limit is required"),
});
