import axios from "axios";

const http = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BACKEND_API,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error.response::", error.response)
    // Check if the error response status is 401 (Unauthorized)
    if (error.response && error.response.status === 401) {
      // Trigger logout (e.g., clearing tokens or user info from store)
      localStorage.clear()
      window.location.href = "/"
      return Promise.reject(new Error("Unauthorized, redirecting to home"));
    }


    return Promise.reject(error);
  }
);

export default http;
