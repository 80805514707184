export function getDashboardKey(data) {
    return `${data?.store_id}-${data?.timeframe_start}-${data?.timeframe_start}-${data?.is_live}-${data?.store_dashboard_types.join('-')}`
}

export function capitalizeText(text) {
  return text
    ?.toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const formatDateTime = (date) => { 
  const inputDate = new Date(date);

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true // Use 12-hour format
};

return inputDate.toLocaleDateString('en-US', options);
}

export function formatNumber(value) {
  const num = parseFloat(value);
  
  // Check if the value has any decimal part after two places
  if (num % 1 === 0) {
    return num.toString(); // Return as a whole number if no decimals
  }

  // Convert to string and ensure two decimal places
  let formatted = num.toFixed(2);
  
  // Remove trailing zero if present after decimal
  if (formatted.endsWith('0')) {
    formatted = parseFloat(formatted).toString(); // Convert back to a number and remove unnecessary zeros
  }
  
  return formatted;
}


export function formatTableHeader(key) {
  return key
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
}

// Utility function to revert formatted header text back to the original key
export function revertFormattedHeader(formattedHeader) {
  return formattedHeader
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .toLowerCase(); // Convert to lowercase
}

// Function to generate headers based on the input array of objects
export function generateTableHeadersFromJson(arrOfObj) {
  if (!Array.isArray(arrOfObj) || arrOfObj.length === 0) {
    return []; // Return an empty array if input is not valid
  }
  
  const firstItem = arrOfObj[0];
  return Object.keys(firstItem)
    .filter(key => typeof firstItem[key] !== 'number') // Exclude keys with number values
    .map(key => formatTableHeader(key)); // Format the keys
}

// Function to generate a mapping of formatted headers to original keys
export function generateTableHeaderKeyMappingFromJson(arrOfObj) {
  const headers = generateTableHeadersFromJson(arrOfObj); // Generate headers
  return headers.reduce((acc, header) => {
    const originalKey = revertFormattedHeader(header); // Get the original key
    acc[header] = originalKey; // Map formatted header to original key
    return acc; // Return the accumulated mapping
  }, {});
}
