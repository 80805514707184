<template>
  <div class="bg-secondary-light w-full h-screen">
   <VueDatePicker   v-model="date" :inline="false" range/>
  </div>
</template>

<script>
import { useToggle, useDark } from "@vueuse/core";

export default {
  name: "StoresView",
  setup() {
    const isDark = useDark();
    const toggleTheme = useToggle(isDark);
    const toggle = () => {
      useToggle(isDark);
    };

    return {
      isDark,
      toggleTheme,
      toggle,
    };
  },
};
</script>
<style lang="scss" scoped></style>
