import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import StoreDashboardView from "@/views/dashboard/StoreDashboardView.vue";
import StorePromoCodesView from "@/views/dashboard/StorePromoCodesView.vue";
import StoreSharedDashboardView from "@/views/dashboard/StoreSharedDashboardView.vue";
import UsersView from "@/views/dashboard/UsersView.vue";
import StoresView from "@/views/dashboard/StoresView.vue";
import PhoneLoginView from "@/views/auth/PhoneLoginView.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: PhoneLoginView,
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("@/layouts/DashboardLayout.vue"),

    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: {
          requiresAuth: true,
          subtitle: "Admin Dashboard"
        },
        default: true,
      },
      {
        path: "users",
        name: "users",
        component: UsersView,
      },
      {
        path: "stores",
        name: "stores",
        component: StoresView,
      },
    ],
  },
  {
    path: "/dashboard",
    redirect: "/admin/dashboard",
  },
  {
    path: "/store",
    component: () => import("@/layouts/DashboardLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: "store_dashboard",
        component: StoreDashboardView,
        meta: {
          requiresAuth: true,
          subtitle: "Store Dashboard"
        },
        default: true,
      },
      {
        path: "promo-codes",
        name: "promo_codes",
        component: StorePromoCodesView,
        meta: {
          requiresAuth: true,
          subtitle: "Promo Codes"
        },
        default: true,
      },
    ],
  },
  {
    path: "/store/dashboard/shared/:token",
    name: "store_shared_dashboard",
    component: StoreSharedDashboardView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(async (to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user data already exists in Vuex store
    const userInfo = store.state.authenticatedUser;

    // If no user info, show the loading indicator
    if (!userInfo) {
      const myDiv = document.body;
      const loadingEl = document.createElement("div");
      loadingEl.setAttribute("class", "loading-container");
      loadingEl.innerHTML = `
        <div class="h-screen z-20 w-full fixed top-0 bg-gray-300 dark:bg-slate-600 flex items-center justify-center flex-col">
          <div class="loading-content">
            <h2>HovaStore</h2>
            <h2>HovaStore</h2>
          </div>
        </div>
      `;
      myDiv.appendChild(loadingEl);
    }

    const loadingContainer = document.querySelector(".loading-container");

    try {
      // Fetch user info if not already available
      if (!userInfo) {
        await store.dispatch("getUserInfo");
      }
      next();

      // Remove loading after successful navigation
      if (loadingContainer) {
        loadingContainer.remove();
      }
    } catch (error) {
      // If error occurs, navigate to login or home
      next("/");
      
      // Remove loading on error
      if (loadingContainer) {
        loadingContainer.remove();
      }
    }
  } else {
    // If no auth is required, just navigate
    next();
    
    // Ensure loading container is removed if present
    const loadingContainer = document.querySelector(".loading-container");
    if (loadingContainer) {
      loadingContainer.remove();
    }
  }
});


export default router;
