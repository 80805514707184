<template>
  <button
    @click="clicked"
    class="form-btn"
    type="submit"
    :disabled="isLoading || isDisabled"
    :class="{
      'btn-primary': color === 'primary',
      'btn-error': color === 'error',
      'btn-info': color === 'info',
      'btn-black': color === 'black',
      'btn-secondary': color === 'secondary',
      'btn-success': color === 'success',
    }"
  >
    <div class="flex justify-center items-center">
      <svg
        v-if="isLoading"
        class="w-5 h-5 text-white animate-spin"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="opacity-0"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          fill="currentColor"
        ></path>
      </svg>
      <span v-else>
        {{ label }}
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: "hs-button",
  props: {
    type: { type: String, default: "submit" },
    color: { type: String, default: "primary" },
    label: { type: String, default: "submit" },
    isLoading: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &-btn {
    @apply text-white font-semibold w-full py-2 rounded-md uppercase hover:shadow-lg hover:bg-opacity-90 h-[40px] disabled:cursor-not-allowed disabled:bg-opacity-60;
  }
}

.btn-primary {
  @apply bg-primary;
}
.btn-error {
  @apply bg-error;
}
.btn-success {
  @apply bg-success;
}
.btn-info {
  @apply bg-info;
}
.btn-secondary {
  @apply bg-secondary text-gray-400;
}
.btn-black {
  @apply bg-black dark:bg-slate-900;
}
</style>
