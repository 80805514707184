<template>
    <Modalview @close-modal="closeModal" customClassContent="sm:px-5 overflow-auto">
        <div class="text-2xl dark:text-gray-300 text-primary font-bold mb-5">
            Create Promo Code
        </div>
        <Form @submit="onSubmit" :validation-schema="promoCodeSchema" class="">
            <hs-field name="code" type="text" label="Promo Code" placeholder="Enter promo code" :required="true"
                :uppercase="true" />
            <div class="mb-4 dark:bg-gray-900 bg-gray-200 rounded-md p-5">
                <div class="dark:text-gray-400 text-gray-500 flex gap-5">
                    <label class="">
                        <input type="radio" v-model="discountType" value="percentage" checked class="mr-1" />
                        Discount Percentage (%)
                    </label>
                    <label class="ml-4">
                        <input type="radio" v-model="discountType" value="amount" class="mr-1" />
                        Discount Amount
                    </label>
                </div>
            </div>
            <div class="md:grid md:grid-cols-2 md:gap-5 items-end">
                <hs-field name="discount_percentage" type="number" label="Discount Percentage (%)"
                    placeholder="Enter discount percentage" :required="true" v-if="discountType === 'percentage'" />

                <hs-field name="discount_amount" type="number" label="Discount Amount"
                    placeholder="Enter discount amount" :required="true" v-model="discount_amount"
                    v-if="discountType === 'amount'" />

                <hs-field name="expiration_date" type="datetime-local" label="Expiration Date"
                    placeholder="Select expiration date" :required="true" />
            </div>

            <div class="md:grid md:grid-cols-2 md:gap-5 items-end">
                <hs-field name="minimum_purchase_required" type="number" label="Minimum Purchase Required"
                    placeholder="Enter minimum purchase amount" :required="true" />

                <hs-field name="usage_limit" type="number" label="Usage Limit" placeholder="Enter usage limit"
                    :required="true" />
            </div>

            <hs-field name="max_allowed_total_discount_amount" type="number" label="Max Allowed Total Discount Amount"
                placeholder="Enter max allowed total discount" :required="false"
                v-model="max_allowed_total_discount_amount" />

            <div class="bg-info rounded-md text-white p-3">
                ⚠️ Heads up! Promo Code details can't be edited once created. ✍️ Please double-check before saving.
            </div>
            <hs-button type="submit" label="CREATE" class="mt-5" :isLoading="isCreating" />
        </Form>
    </Modalview>
</template>

<script>
import { Form } from "vee-validate";
import { promoCodeSchema } from "@/validations/promoCodeSchema";
import { toast } from "vue3-toastify";

export default {
    name: "CreatePromoCode",
    components: { Form },
    data() {
        return {
            discountType: "percentage",
            code: null,
            discount_percentage: null,
            expiration_date: null,
            minimum_purchase_required: null,
            usage_limit: null,
            discount_amount: null,
            max_allowed_total_discount_amount: null,
        };
    },
    props: {
        showPromoModal: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        isCreating() {
            return this.$store.getters.isLoadingCreatePromoCode; // Use the new loading state
        },
        selectedStore() {
            return this.$store.getters.getSelectedStore;
        }
    },
    methods: {
        formatDate(inputDateStr) {
            const inputDate = new Date(inputDateStr);

            const day = String(inputDate.getDate()).padStart(2, '0');
            const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const year = inputDate.getFullYear();
            const hours = String(inputDate.getHours()).padStart(2, '0');
            const minutes = String(inputDate.getMinutes()).padStart(2, '0');
            const seconds = String(inputDate.getSeconds()).padStart(2, '0');

            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        },
        async onSubmit(values) {
            const promoCodeData = {
                code: values.code,
                discount_percentage: values.discount_percentage,
                expiration_date: this.formatDate(values.expiration_date),
                minimum_purchase_required: values.minimum_purchase_required,
                usage_limit: values.usage_limit,
                discount_amount: values.discount_amount,
                max_allowed_total_discount_amount: values.max_allowed_total_discount_amount,
                store_id: this.selectedStore
            };

            try {
                await this.$store.dispatch('createPromoCode', {
                    store_id: this.selectedStore, // The store ID
                    promoCodeData
                });
                this.$emit('close-modal');
                toast.success("Promo code created successfully!")
            } catch (error) {
                toast.error(
                    error?.response?.data?.error?.message || "An error occurred when creating promo code"
                );
            }
        },
        closeModal() {
            this.$emit("close-modal");
        },
        promoCodeSchema() {
            return promoCodeSchema;
        },

    },
};
</script>

<style>
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: invert(20%) sepia(1) saturate(5000%) hue-rotate(310deg) brightness(80%);
}
</style>