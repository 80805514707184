<template>
    <Modalview @close-modal="closeModal" title="Upate Promo Code" customClassContent="sm:px-5 overflow-auto">
        <hr class="bg-slate-500 mb-5 mt-2 border-none h-[1px]" />
        <div class="dark:bg-slate-900 bg-slate-200 rounded-md p-2">
            <div class="promo-codes__card">
                <div class="bg-primary w-16 rounded-lg text-center py-2 text-sm flex flex-col font-bold text-white">
                    <span>{{ selectedPromoCode.discount_percentage ?
                        `${getFormattedValue(selectedPromoCode.discount_percentage)}%` :
                        `${getFormattedValue(selectedPromoCode.discount_amount)} RF`
                        }}</span>
                    <span>OFF</span>
                </div>
                <div class="flex-1">
                    <div class="text-md dark:text-white text-gray-900 flex items-center gap-2">
                        {{ selectedPromoCode.code }}
                        <span class="text-[12px]">{{ selectedPromoCode.is_active ?
                            '✅' : '❌' }}</span>
                    </div>
                    <div class="text-sm">{{ formatDate(selectedPromoCode.expiration_date, false) }}</div>
                    <div class="mb-3"></div>
                    <p class="dark:text-white text-gray-900"><span class="text-gray-400 text-md">Minimum
                            Purchase:</span>
                        RF {{ getFormattedValue(selectedPromoCode.minimum_purchase_required) }}</p>
                    <p class="dark:text-white text-gray-900 text-md"><span class="text-gray-400">Usage Limit:</span>
                        {{ selectedPromoCode.usage_count }} / {{ selectedPromoCode.usage_limit }}</p>
                </div>
            </div>
        </div>
        <div class="dark:bg-slate-900 bg-slate-200 w-full rounded-md my-5">
            <div class="dark:text-slate-100 flex w-full items-center p-4">
                <div class="flex-1">
                    <span class="font-bold mr-2">{{ selectedPromoCode.code }}</span> is
                    {{ this.selectedPromoCode.is_active ? 'activate' : 'deactivate' }}d!
                </div>
                <div class="w-[130px]">
                    <hs-button type="submit" :color="selectedPromoCode.is_active ? 'error' : 'success'"
                        :label="buttonLabel" :isLoading="isLoading" @click="handlePromoCodeActivation" />
                </div>
            </div>
        </div>
        <div class="dark:bg-slate-900 bg-slate-200 rounded-md p-2">
            <div class="dark:bg-slate-800 bg-slate-50 p-4">
                <hs-field name="expiration_date" type="datetime-local" label="Expiration Date"
                    placeholder="Select expiration date" :required="true" v-model="expirationDate" />
                <hs-field name="usage_limit" type="number" label="Usage Limit" placeholder="Enter usage limit"
                    :required="true" v-model="usageLimit" @input="usageLimit = $event.target.value" />
                <div v-if="!selectedPromoCode.is_active" class="bg-info rounded-md text-white p-3">
                    ✨ Promo Code must be activated before updating! 🔄
                </div>
                <hs-button type="submit" label="update" class="mt-5" :isLoading="isUpdating"
                    @click="handleUpdatePromoCode" :disabled="!selectedPromoCode.is_active" />
            </div>
        </div>
    </Modalview>
</template>

<script>
import { toast } from "vue3-toastify";
import { formatNumber } from '@/helper/utils';

export default {
    name: "UpdatePromoCode",
    props: ['selectedPromoCode'],
    data() {
        return {
            isActivating: false,
            buttonLabel: '',
            expirationDate: "", // Default date
            usageLimit: '', // Default usage limit
        };
    },
    computed: {
        selectedStore() {
            return this.$store.getters.getSelectedStore;
        },
        isLoading() {
            return this.$store.getters.isLoadingActivatePromoCode;
        },
        isUpdating() {
            return this.$store.getters.isLoadingUpdatePromoCode;
        }
    },
    methods: {
        closeModal() {
            this.$emit("close-modal");
        },
        getFormattedValue(value) {
            return formatNumber(value)
        },
        formatDate(dateString, applyTimezone = true, timezoneOffset = 2) {
            // Parse the input GMT date string
            const date = new Date(dateString);

            // If applyTimezone is true, adjust the date by the given timezone offset (in hours)
            if (applyTimezone) {
                const offsetInMs = timezoneOffset * 60 * 60 * 1000; // Convert target offset to milliseconds
                date.setTime(date.getTime() + offsetInMs); // Adjust the time by the target offset
            }

            // Format the date to 'Dec 31, 2024 23:59'
            const options = {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'UTC' // Force UTC display for formatting purposes
            }
            return date.toLocaleString('en-US', options);
        },
        formatPayloadDateToSend(inputDateStr) {
            const inputDate = new Date(inputDateStr);

            const day = String(inputDate.getDate()).padStart(2, '0');
            const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const year = inputDate.getFullYear();
            const hours = String(inputDate.getHours()).padStart(2, '0');
            const minutes = String(inputDate.getMinutes()).padStart(2, '0');
            const seconds = String(inputDate.getSeconds()).padStart(2, '0');

            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        },
        async handlePromoCodeActivation() {
            const promoCodeData = {
                id: this.selectedPromoCode.id,
                type: this.buttonLabel
            };
            try {
                await this.$store.dispatch('activatePromoCode', promoCodeData);
                this.$emit('close-modal');
                toast.success(`${this.selectedPromoCode.code} ${this.buttonLabel}d successfully!`)
            } catch (error) {
                toast.error(
                    error?.response?.data?.error?.message ||
                    `An error occurred when ${this.selectedPromoCode.is_active ? 'deactivating' : 'activating'} promo code`
                );
            }
        },
        async handleUpdatePromoCode() {
            const promoCodeData = {
                id: this.selectedPromoCode.id,
                data: {
                    expiration_date: this.formatPayloadDateToSend(this.expirationDate),
                    usage_limit: +this.usageLimit
                }
            };
            try {
                await this.$store.dispatch('updatePromoCode', promoCodeData);
                this.$emit('close-modal');
                toast.success(`Promo code updated successfully!`)
            } catch (error) {
                toast.error(
                    error?.response?.data?.error?.message ||
                    `An error occurred when updating promo code`
                );
            }
        },
        formatDateToDatetimeLocal(dateString) {
            // Parse the string into a Date object
            const date = new Date(dateString);

            // Ensure two digits for month, day, hour, minute
            const twoDigit = (num) => String(num).padStart(2, '0');

            // Extract parts of the date
            const year = date.getFullYear();
            const month = twoDigit(date.getMonth() + 1); // Months are 0-based
            const day = twoDigit(date.getDate());
            const hours = twoDigit(date.getHours());
            const minutes = twoDigit(date.getMinutes());

            // Format as YYYY-MM-DDTHH:MM
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        }
    },
    watch: {
        selectedPromoCode: {
            immediate: true,
            handler(newPromoCode) {
                if (newPromoCode) {
                    this.buttonLabel = newPromoCode.is_active ? 'deactivate' : 'activate';
                    this.usageLimit = newPromoCode.usage_limit;
                    this.expirationDate = this.formatDateToDatetimeLocal(newPromoCode.expiration_date);
                }
            }
        }
    },
};
</script>

<style></style>