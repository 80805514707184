<template>
  <div class="relative overflow-x-auto sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <!-- Dynamic column headers -->
          <th v-for="(header, index) in headers" :key="index" scope="col" class="px-6 py-3">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in data" :key="rowIndex"
          class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
          <td v-for="(header, index) in headers" :key="index" class="px-6 py-4">
            {{ row[headerKeyMapping[header]] ? row[headerKeyMapping[header]] : "N/A" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'HSTable',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    headerKeyMapping: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
@media (max-width: 640px) {
  table {
    display: block;
    width: auto;
  }

  th,
  td {
    white-space: nowrap;
  }
}
</style>