<template>
    <div class="promo-codes-list">
        <div v-if="loadingPromoCodes" class="flex flex-wrap gap-4 items-center">
            <div v-for="n in 8" :key="n" class="flex flex-1">
                <PromoCodeSkeleton class="flex" />
            </div>
        </div>
        <div v-for="item in promoCodes" :key="item.id" class="md:w-[calc(33%-16px)] w-full ml-2 mb-2" v-else>
            <div class="promo-codes__card" @click="handleChange(item)">
                <div class="bg-primary w-16 rounded-lg text-center py-2 text-sm flex flex-col font-bold text-white">
                    <span>{{ item.discount_percentage ? `${getFormattedValue(item.discount_percentage)}%` :
                        `${getFormattedValue(item.discount_amount)} RF`
                        }}</span>
                    <span>OFF</span>
                </div>
                <div class="flex-1">
                    <div class="text-md dark:text-white text-gray-900 flex items-center gap-2">
                        {{ item.code }}
                        <span class="text-[12px]">
                            {{ item.is_active ? '✅':'❌' }}
                        </span>
                    </div>
                    <div class="text-sm">Expired on {{ formatDate(item.expiration_date, false) }}</div>
                    <div class="mb-3"></div>
                    <p class="dark:text-white text-gray-900"><span class="text-gray-400 text-md">Minimum
                            Purchase:</span>
                        RF {{ getFormattedValue(item.minimum_purchase_required) }}</p>
                    <p class="dark:text-white text-gray-900 text-md"><span class="text-gray-400">Usage Limit:</span>
                        {{ item.usage_count }} / {{ item.usage_limit }}</p>
                </div>
                <div class="flex flex-col justify-between h-full gap-10">
                    <button class="btn-icon" @click="handleCardChange(item)" @click.stop>
                        <vue-feather type="edit-2" class="" size="16" />
                    </button>
                    <div :class="{ 'active': item.checked }"
                        class="check-icon flex items-center justify-center text-slate-100 w-8 h-8 bg-slate-100 border-gray-300 rounded-full">
                        <vue-feather type="check" class="" size="20" />
                    </div>
                </div>
            </div>
        </div>
        <UpdatePromoCode v-if="showUpdateModal" @close-modal="handleModalClose" :selectedPromoCode="selectedPromoCode" />
    </div>
</template>

<script>
import PromoCodeSkeleton from '@/skeletons/PromoCodeSkeleton.vue';
import { formatNumber } from '@/helper/utils';
import UpdatePromoCode from "@/components/modals/promo_code/UpdatePromoCode.vue"

export default {
    name: "PromoCodes",
    props: ['promoCodes'],
    data() {
        return {
            showUpdateModal: false,
            selectedPromoCode: null
        }
    },
    components: {
        PromoCodeSkeleton,
        UpdatePromoCode,
    },
    computed: {
        loadingPromoCodes() {
            return this.$store.getters.isLoadingPromoCodes;
        },
    },
    methods: {
        getFormattedValue(value) {
            return formatNumber(value)
        },
        formatDate(dateString, applyTimezone = true, timezoneOffset = 2) {
            // Parse the input GMT date string
            const date = new Date(dateString);

            // If applyTimezone is true, adjust the date by the given timezone offset (in hours)
            if (applyTimezone) {
                const offsetInMs = timezoneOffset * 60 * 60 * 1000; // Convert target offset to milliseconds
                date.setTime(date.getTime() + offsetInMs); // Adjust the time by the target offset
            }

            // Format the date to 'Dec 31, 2024 23:59'
            const options = {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'UTC' // Force UTC display for formatting purposes
            }
            return date.toLocaleString('en-US', options);
        },
        handleChange(item) {
            item.checked = !item.checked
            this.$emit('promo-codes-selected', this.promoCodes.filter(p => p.checked));
        },
        handleInputChange(item) {
            item.checked = !item.checked
            this.$emit('promo-codes-selected', this.promoCodes.filter(p => p.checked));
        },
        handleCardChange(item) {
            this.showUpdateModal = true;
            this.selectedPromoCode = item
        },
        handleModalClose() {
            const modal = document.querySelector(".modal");
            modal.classList.add("closing");
            setTimeout(() => {
                modal.classList.remove("closing");
                this.showUpdateModal = false;
            }, 1000);
        },
    }
}
</script>

<style scoped></style>