<template>
    <div class="store-header">
        <div class="store-header-menu">
            <div class="header-menu">
                <button v-for="(item, index) in menuItems" :key="index"
                    :class="{ 'header-menu__item': true, 'active': isActive(item.route) }"
                    @click="handleActiveMenuItem(item.route)">
                    {{ item.label }}
                </button>
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderMenu",
    data() {
        return {
            menuItems: [
                { label: 'Dashboard', route: 'dashboard' },
                { label: 'Promo codes', route: 'promo-codes' },
            ],
            activeMenuItem: 0,
        }
    },
    methods: {
        handleActiveMenuItem(route) {
            this.$router.push(`/store/${route}`);
        },
        isActive(route) {
            return this.$route.path.includes(route); // Check if the current route matches the menu item route
        },
    },
}
</script>

<style lang="scss" scoped></style>
