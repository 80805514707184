import http from "@/helper/http";
import api from "@/helper/api";

export default {
  state: {
    loadingStores: false,
    stores: [],
  },
  mutations: {
    SET_LOADING_STORE(state, loadingStores) {
      state.loadingStores = loadingStores;
    },
    SET_STORES(state, stores) {
      state.stores = stores;
    },
  },
  actions: {
    getAllUsersStore({ commit }) {
      commit("SET_LOADING_STORE", true);
      return new Promise((resolve, reject) => {
        http
          .get(api.get_user_stores)
          .then((response) => {
            commit("SET_STORES", response?.data);
            commit("SET_LOADING_STORE", false);
            resolve(response?.data);
          })
          .catch((error) => {
            commit("SET_LOADING_STORE", false);
            reject(error);
          });
      });
    },
  },
  getters: {
    isLoadingStores: (state) => state.loadingStores,
    getUserStores: (state) => state.stores,
  },
};