<template>
  <div>
    <div class="form-input" :class="inputClass">
      <div class="form-input__label" v-if="label">
        {{ label }}<span class="text-error" v-if="required">*</span>
      </div>
      <div :class="{ 'input-error': errorMessage }" class="form-input__container">
        <!-- Use vee-validate's Field with event for updating v-model -->
        <Field
          :name="name"
          :id="name"
          :type="type"
          :placeholder="placeholder"
          :class="['form-input__container--field', { 'uppercase': uppercase }]"
          :style="{ textTransform: uppercase ? 'uppercase' : 'none' }"
          :value="modelValue" 
          @input="handleInput" 
        />
        <ErrorMessage :name="name" class="form-input__error" />
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "HsField",
  components: { Field, ErrorMessage },
  props: {
    type: { type: String, default: "text" },
    required: { type: Boolean, default: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    placeholder: { type: String, default: "" },
    inputClass: { type: String, default: "" },
    uppercase: { type: Boolean, default: false },
    modelValue: { type: [String, Number, Date], default: "" }, // modelValue prop for v-model binding
  },
  emits: ["update:modelValue"], // Emit event for v-model
  methods: {
    handleInput(event) {
      this.$emit("update:modelValue", event.target.value); // Emit the input value
    },
  },
};
</script>
