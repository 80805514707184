<template>
  <div>
    <div class="form-input" :class="inputClass">
      <div class="form-input__label" v-show="label">
        {{ label }}<span class="text-error" v-if="required">*</span>
      </div>
      <div
        :class="{ 'input-error': errorMessage && !meta.valid }"
        class="form-input__container"
      >
        <input
          class="form-input__container--field"
          :name="inputName"
          :id="name"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          @input="handleChanges"
          @blur="handleBlur"
          :style="{ textTransform: uppercase ? 'uppercase' : 'none' }"
        />
        <div class="form-input__error" v-show="errorMessage">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { toRefs } from "vue";

export default {
  name: "hs-input",
  props: {
    type: { type: String, default: "text" },
    required: { type: Boolean, default: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    placeholder: { type: String, default: "" },
    inputClass: { type: String, default: "" },
    uppercase: { type: Boolean, default: false },
  },
  methods: {
    handleChanges(event) {
      this.$emit("input", event.target.value);
    },
  },
  setup(props) {
    const { name: inputName } = toRefs(props);
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(inputName);

    return {
      inputName,
      inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped></style>
