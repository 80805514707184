<template>
    <div class="store-promocode">
        <HeaderMenu>
            <div class="flex gap-4 flex-wrap items-center justify-center">
                <hs-select v-if="selectedStore" :value="selectedStore" @value-change="handleSelectedStore"
                    v-model="selectedStore" class="min-w-[200px]" custom-class="text-gray-800 border-gray-800"
                    :options="getOptionsForStore(stores)" placeholder="Select store" :isLoading="isLoadingStores" />
                <button class="btn-icon bg-info px-4 py-2 flex items-center rounded-md" @click="handleOpenCreateModal">
                    <vue-feather type="plus" class="text-white mr-2" size="20" />
                    <span class="text-white text-sm">PROMO CODE</span>
                </button>
                <div class="flex gap-4" v-if="checkedPromoCodes.length > 0">
                    <button class="btn-icon bg-success px-4 py-2 flex items-center rounded-md"
                        @click="handleOpenCopyModal">
                        <vue-feather type="copy" class="text-white mr-2" size="20" />
                        <span class="text-white text-sm">COPY TO STORES</span>
                    </button>
                </div>
            </div>
        </HeaderMenu>

        <div class="px-6 sm:px-4 py-6">
            <PromoCodes :promo-codes="allPromoCodes" @promo-codes-selected="handleCheckedItemsChanged" />
        </div>
        <div>
            <CreatePromoCode v-if="showCreateModal" @close-modal="handleModalClose" />
            <CopyPromoCodeToStore v-if="showCopyModal" :selectedPromoCodes="checkedPromoCodes"
                @close-modal="handleCopyModalClose" />
        </div>
    </div>
</template>

<script>
import HeaderMenu from '@/components/menu/HeaderMenu.vue';
import PromoCodes from '@/components/promo_code/PromoCodes.vue';
import { toast } from "vue3-toastify";
import CreatePromoCode from '@/components/modals/promo_code/CreatePromoCode';
import CopyPromoCodeToStore from '@/components/promo_code/CopyPromoCodeToStore.vue';

export default {
    name: "StorePromoCodesView",
    components: {
        HeaderMenu,
        PromoCodes,
        CreatePromoCode,
        CopyPromoCodeToStore,
    },
    data() {
        return {
            selectedStore: null,
            stores: ["Hovastore"],
            allPromoCodes: [],
            showCreateModal: false,
            showCopyModal: false,
            checkedPromoCodes: [],
        }
    },
    methods: {
        handleCheckedItemsChanged(checkedItems) {
            // Update the list of checked promo codes
            this.checkedPromoCodes = checkedItems;
        },
        handleSelectedStore(newStore) {
            this.selectedStore = newStore;
            this.$store.commit("SET_SELECTED_STORE", newStore);
            this.getAllPromoCodes()
        },
        capitalizeText(text) {
            return text
                ?.toLowerCase()
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        },
        getOptionsForStore(data) {
            return data.map((item) => ({
                value: item.id,
                name: item.name,
            }));
        },
        getAllPromoCodes() {
            if (this.selectedStore) {
                this.$store
                    .dispatch("getAllPromoCodes", this.selectedStore).then(response => {
                        this.allPromoCodes = response
                    }).catch((error) => {
                        toast.error(
                            error?.response?.data?.error?.message || "An error occurred when fetching promo codes"
                        );
                    });
            }
        },
        handleModalClose() {
            const modal = document.querySelector(".modal");
            modal.classList.add("closing");
            setTimeout(() => {
                modal.classList.remove("closing");
                this.showCreateModal = false;
            }, 1000);
        },
        handleCopyModalClose() {
            const modal = document.querySelector(".modal");
            modal.classList.add("closing");
            setTimeout(() => {
                modal.classList.remove("closing");
                this.showCopyModal = false;
            }, 1000);
        },
        handleOpenCreateModal() {
            this.showCreateModal = true;
            document.body.style.overflow = "hidden";
        },
        handleOpenCopyModal() {
            this.showCopyModal = true;
            document.body.style.overflow = "hidden";
        },
    },
    mounted() {
        this.$store
            .dispatch("getAllUsersStore")
            .then((response) => {
                this.stores = response?.owner_of;
                if (this.stores.length > 0) {
                    this.selectedStore = this.stores[0].id;
                    this.$store.commit("SET_SELECTED_STORE", this.stores[0].id);
                    this.getAllPromoCodes()
                }
            })
            .catch((error) => {
                toast.error(
                    error?.response?.data?.error?.message || "An error occurred when fetching stores"
                );
            });
    },
}
</script>

<style lang="scss" scoped></style>