import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/scss/index.css";
import VueFeather from "vue-feather";
import HSButton from "@/components/form/button/HSButton";
import HSInput from "@/components/form/inputs/HSInput";
import HSField from "@/components/form/inputs/HSField";
import SideBar from "@/components/sidebar/SideBar";
import StatsCard from "@/components/cards/StatsCard";
import StoreCard from "@/components/cards/StoreCard";
import SearchBar from "@/components/cards/SearchBar";
import SystemErrors from "@/components/dashboard/SystemErrors";
import HSChart from "@/components/dashboard/HSChart";
import UpdateProfile from "@/components/modals/profile/UpdateProfile";
import ShareDashboard from "@/components/modals/store/ShareDashboard";
import UpdateStore from "@/components/modals/store/UpdateStore";
import ModalView from "@/components/modals/ModalView";
import HSTable from "@/components/table/HSTable"
import HSDropdown from "@/components/form/dropdown/HSDropdown"
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Permissions from "./helper/permissions";
import HSSelect from "./components/form/inputs/HSSelect";
import VueChartkick from "vue-chartkick";
import "chartkick/chart.js";
import DateFilter from "@/components/dashboard/DateFilter.vue";
import { formatDateTime, capitalizeText, formatNumber, generateTableHeaderKeyMappingFromJson, generateTableHeadersFromJson } from "@/helper/utils";
import VCalendar from 'v-calendar';
import { VDatePicker } from "v-calendar";
import 'v-calendar/style.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

// Check if all environment variables are defined
const areEnvVarsDefined = Object.values(firebaseConfig).every(value => value !== undefined);

if (areEnvVarsDefined) {
  // Initialize Firebase only if all environment variables are defined
  const firebaseApp = initializeApp(firebaseConfig);
  getAnalytics(firebaseApp);
} else {
  console.warn("Firebase environment variables are not fully defined. Firebase initialization skipped.");
}

const app = createApp(App);

app
  .component(VueFeather.name, VueFeather)
  .component("hs-button", HSButton)
  .component("hs-input", HSInput)
  .component("hs-field", HSField)
  .component("hs-table", HSTable)
  .component("hs-dropdown", HSDropdown)
  .component("side-bar", SideBar)
  .component("Statscard", StatsCard)
  .component("Storecard", StoreCard)
  .component("Searchbar", SearchBar)
  .component("SystemErrors", SystemErrors)
  .component("hs-chart", HSChart)
  .component("Updateprofile", UpdateProfile)
  .component("Sharedashboard", ShareDashboard)
  .component("Updatestore", UpdateStore)
  .component("Modalview", ModalView)
  .component("hs-select", HSSelect)
  .component("DateFilter", DateFilter)
  .component("VDatePicker", VDatePicker)

app.config.globalProperties.permissions = new Permissions([
  "SUPER_ADMIN",
  "AUDITOR",
]);

app.config.globalProperties.$formatDateTime = formatDateTime;
app.config.globalProperties.$capitalizeText = capitalizeText;
app.config.globalProperties.$formatNumber = formatNumber;
app.config.globalProperties.$generateTableHeaderKeyMappingFromJson = generateTableHeaderKeyMappingFromJson;
app.config.globalProperties.$generateTableHeadersFromJson = generateTableHeadersFromJson;

app.use(store);
app.use(router);
app.use(Vue3Toasity, {
  autoClose: 3000,
});
app.use(VCalendar, {});
app.use(VueChartkick);
app.mount("#app");
