<template>
  <Modalview @close-modal="closeModal" :customClassContent="'h-auto overflow-auto'" :title="copyPromoCodeResponse.length === 0 ? 'Copy Promo Codes to Stores' : ''">
    <div class="promocode-copy-container overflow-auto">
      <div v-if="copyPromoCodeResponse.length === 0">
        <div class="my-5 border rounded-md">
          <div
            class="dark:text-gray-300 text-gray-900 text-xl px-3 py-3 dark:bg-gray-900 bg-gray-300 rounded-tl-md rounded-tr-md">
            Selected
            Promo codes</div>
          <div class="border-t px-3 py-3">
            <div v-for="(item, index) in selectedPromoCodes" :key="index"
              class="cursor-pointer bg-primary text-white inline-block mr-5 px-3 py-2 rounded-md text-sm font-semibold">
              {{ item.code }}
            </div>
          </div>
        </div>


        <div class="my-8 border rounded-md">
          <div
            class="dark:text-gray-300 text-gray-900 text-xl px-3 py-3 dark:bg-gray-900 bg-gray-300 rounded-tl-md rounded-tr-md">
            Select stores</div>
          <div class="border-t px-3 py-3 space-y-3">
            <div v-for="(item, index) in ownerStores" :key="index" @click="toggleSelection(item)" :class="{
              'bg-blue-500 text-white': selectedStoresToCopy.find(i => i.name === item.name),
              'bg-gray-200': !selectedStoresToCopy.find(i => i.name === item.name)
            }" class="cursor-pointer inline-block mr-5 px-3 py-1 rounded-md text-sm font-semibold">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="flex items-center mb-4">
          <input id="default-checkbox" type="checkbox" v-model="canOverwrite" @onchange="handleCanOverwrite"
            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600">
          <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Overwrite Existing Promo Codes in Store</label>
        </div>
        <hs-button type="submit" label="Copy promo codes to stores" class="mt-5" :isLoading="isCopying"
          :disabled="isSubmitDisabled" @click="onCopySubmit" />
      </div>

      <div class="my-5 border rounded-md" v-else>
        <div
          class="dark:text-gray-300 text-gray-900 text-xl px-3 py-3 dark:bg-gray-900 bg-gray-300 rounded-tl-md rounded-tr-md">
          Results from Copying Promo Codes to Stores</div>
        <div class="border-t px-3 py-3">
          <hs-table :headers="$generateTableHeadersFromJson(copyPromoCodeResponse)" :data="copyPromoCodeResponse"
            :headerKeyMapping="$generateTableHeaderKeyMappingFromJson(copyPromoCodeResponse)" />
        </div>
      </div>

    </div>
  </Modalview>
</template>

<script>
import { toast } from 'vue3-toastify';
export default {
  name: "CopyPromoCodeToStore",
  props: ['selectedPromoCodes'],
  data() {
    return {
      selectedStoresToCopy: [],
      dashboardTypes: ['store 1', 'store 2'],
      canOverwrite: false,
      isSubmitDisabled: true,
      copyPromoCodeResponse: []
    }
  },
  computed: {
    ownerStores() {
      const stores = this.$store.getters.getUserStores
      return stores.owner_of;
    },
    isCopying() {
      return this.$store.getters.isLoadingCopyPromoCode
    }
  },
  watch: {
    selectedPromoCodes: {
      handler() {
        this.checkSubmitDisabled();
      },
      deep: true
    },
    selectedStoresToCopy: {
      handler() {
        this.checkSubmitDisabled();
      },
      deep: true
    }
  },
  methods: {
    checkSubmitDisabled() {
      this.isSubmitDisabled = this.selectedPromoCodes.length === 0 || this.selectedStoresToCopy.length === 0;
    },
    handleCanOverwrite() {
      this.canOverwrite = !this.canOverwrite
    },
    closeModal() {
      this.$emit("close-modal");
    },
    toggleSelection(item) {
      if (this.selectedStoresToCopy.find(i => i.name === item.name)) {
        // Item is already selected, so deselect it
        this.selectedStoresToCopy = this.selectedStoresToCopy.filter((i) => i !== item);
      } else {
        // Item is not selected, so select it
        this.selectedStoresToCopy.push(item);
      }
    },
    async onCopySubmit() {
      const payload = {
        promo_code_ids: this.selectedPromoCodes.map(obj => obj.id),
        target_store_ids: this.selectedStoresToCopy.map(obj => obj.id),
        overwrite: this.canOverwrite
      };

      try {
        const response = await this.$store.dispatch('copyPromoCodesToStores', payload);
        this.copyPromoCodeResponse = response
      } catch (error) {
        toast.error(
          error?.response?.data?.error?.message || "An error occurred when copying promo codes"
        );
      }
    },
  },
};
</script>

<style></style>
