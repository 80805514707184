<template>
    <div role="status" class="animate-pulse min-w-[250px] h-[100px]">
        <div class="w-full h-full bg-gray-300 rounded-md dark:bg-gray-600"></div>
    </div>
</template>

<script>
export default {
    setup() {
        return {}
    }
}
</script>

<style lang="scss" scoped></style>